//Dependencies & helpers
import React from "react";
import {translation, UserContext} from "../../helpers";
//Styles
import "./Footer.css";

const Footer = () => {
  //Use translations
  const [state] = React.useContext(UserContext);
  const t = translation[state.lang].footer;
  return (
    <footer>
      <div className="distributed">
        <h3>{t.distributedBy}</h3>
        <div>
          <img src={process.env.PUBLIC_URL + "images/logo-allder.svg"} alt="Allder" />
          <address>
            <h5>Managing Director</h5>
            <p>Piccenna Daniel</p>
            <h5>E-mail</h5>
            <a href="mailto:dpiccenna@gmail.com">dpiccenna@gmail.com</a>
            <h5>Mobile</h5>
            <a href="phone:3294214118">329.42.14.118</a>
          </address>
        </div>
      </div>
      <div className="produced">
        <h3>{t.producedBy}</h3>
        <div>
          <img
            src={process.env.PUBLIC_URL + "images/bio-elettronica.png"}
            alt="Bio Elettronica"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;

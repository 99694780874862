//Dependencies & helpers
import React from "react";
import {NavLink} from "react-router-dom";
import {translation, UserContext} from "../../helpers";
//Components
import Translator from "../Translator/Translator";
//Styles
import "./Navbar.css";

const Navbar = () => {
  //Use translations
  const [state] = React.useContext(UserContext);
  const t = translation[state.lang].nav;

  const nav = [
    { label: t.home, url: "/" },
    { label: t.about, url: "/about" },
    { label: t.bruxoff, url: "/bruxoff" },
    { label: t.tech, url: "/tech" },
    { label: t.howto, url: "/how-to" },
    { label: t.download, url: "/download" },
    /*{ label: t.shop, url: "/shop" },*/
    { label: t.contacts, url: "/contacts" },
  ];

  //Render nav links
  const renderNavLinks = () => {
    return nav.map((link) => {
      return (
        <li key={link.label}>
          <NavLink exact activeClassName="active" to={link.url}>
            {link.label}
          </NavLink>
        </li>
      );
    });
  };

  return (
    <header id="desktop">
      <nav>
        <div className="logo">
          <a href="/">
            <img src={process.env.PUBLIC_URL + "/images/logo-Bruxoff.svg"} alt="Bruxoff" />
          </a>
        </div>
        <ul>{renderNavLinks()}</ul>
        <Translator />
      </nav>
    </header>
  );
};

export default Navbar;

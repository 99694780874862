import React from "react";

const translation = {
  it: {
    flag: ["/images/it.png"],
    label: "Italiano",
    nav: {
      home: "Home",
      about: "Il bruxismo",
      bruxoff: "Bruxoff",
      tech: "La tecnologia",
      download: "Download",
      howto: "Come si usa",
      shop: "Shop",
      contacts: "Contatti",
    },
    footer: {
      distributedBy: "Distribuito da",
      producedBy: "Prodotto da",
    },
    home: {
      blocks: [
        {
          img: ["home-01.png"],
          title:
            "Il bruxismo è la causa principale di dolori muscolari e articolari, cefalea al risveglio, usura dentale, compromissione di impianti dentali.",
          paragraph: `Il bruxismo è una parafunzione caratterizzata dal serramento e dal digrignamento dei denti durante il sonno, associata ai micro risvegli. 
          Recenti studi hanno dimostrato come il bruxismo sia associato ad un'attivazione del sistema nervoso autonomo in grado di generare una cascata di eventi fisiologici che si conclude con un aumento della frequenza cardiaca precedente di pochi secondi 
          alla contrazione dei muscoli masseteri.`,
        },
        {
          title: `Bruxoff.
            La diagnosi del Bruxismo`,
          paragraph: `Estremamente semplice, piccolo e portatile, Bruxoff è il nuovo holter portatile in grado di registrare simultaneamente l'attività dei muscoli masseteri e del cuore.`,
          button: "Scopri di più",
          link: "/bruxoff",
          img: ["home-02.gif"],
        },
        {
          img: ["home-03.png"],
          title: `Bruxoff.
            La diagnosi del Bruxismo`,
          paragraph: `Estremamente semplice, piccolo e portatile, Bruxoff è il nuovo holtrer portatile in grado di registrare simultaneamente l'attività dei muscoli masseteri e del cuore.`,
          link: "/about",
          button: "Scopri di più",
        },
      ],
    },
    about: {
      blocks: [
        {
          title:
            "Bruxismo deriva dalla parola greca “brychein,” che significa “digrignare e strofinare le due opposte arcate dentali”",
          subtitle:
            "Il bruxismo è la causa principale di dolori muscolari e articolari, cefalea al risveglio, usura dentale, compromissione di impianti dentali.",
          img: ["about-01.png"],
        },
        {
          img: ["about-02.png"],
          title: "La diagnosi del bruxismo",
          paragraph: `Il bruxismo è una parafunzione caratterizzata dal serramento e dal digrignamento dei denti durante il sonno, associata ai cosiddetti micro risvegli.
          Recenti studi hanno dimostrato come il bruxismo sia associato ad un'attivazione del sistema nervoso autonomo causa di una cascata di eventi fisiologici che si conclude con un aumento della frequenza cardiaca precedente di pochi secondi alla contrazione dei muscoli masseteri.`,
        },
        {
          full: true,
          items: [
            {
              img: "about-03.gif",
              },
            {
              img: "about-04.gif",
              },
          ],
          title: "Danni causati dal bruxismo",
          paragraph: `Il bruxismo è la principale causa di insuccesso implantare per sovraccarico occlusale. E' inoltre responsabile di fratture dei restauri protesici e conservativi. 
                  La sua presenza può peggiorare condizioni cliniche già esistenti di disturbi temporomandibolari sia muscolari che articolari.`,
          subParagraph: `Il bruxismo può essere definito della veglia o del sonno a seconda delle sue manifestazioni circadiane. La forma della veglia (AB "awake bruxism") presenta forti associazioni con alti livelli di stress, fumo e nicotina. 
                  Al contrario, il bruxismo del sonno (SB "sleep bruxism") è principalmente regolato a livello del sistema nervoso centrale e non risulta influenzato da interferenze occlusali. 
                  Alcune associazioni sono state riscontrate con l'assunzione di alcolici e fumo, ma rimane prevalentemente un disturbo del sonno caratterizzato dal serramento o dal digrignamento, finanche dalle protrusione mandibolare associati ad episodi di micro-risvegli ("micro-arousals") a livello centrale.`,
        },
      ],
    },
    bruxoff: {
      blocks: [
        {
          title:
            "Bruxoff®",
          paragraph:
            "Bruxoff®  un holter portatile per monitorare i segnali elettromiografici (EMG) dei muscoli masseteri e la frequenza cardiaca in pazienti in cui si sospetta la presenza di SB o di un eccesso di attività EMG dei muscoli masticatori che possono essere causa di effetti dannosi per il sistema stomatogantico quali: insuccessi protesici, usura dentale, dolori orofaciali.\n" +
              "La raccolta di entrambi i segnali rendono il dispositivo Bruxoff® uno strumento validato per l’indagine strumentale dello SB, in quanto in letteratura si è evidenziata la correlazione tra l’aumento dell’attività EMG dei muscoli masseteri e della frequenza cardiaca nel sonno ad eventi di micro-risvegli (arousals) del sistema nervoso centrale in cui si possono varificare anche episodi di SB.\n" +
              "La raccolta di entrambi questi segnali rende il dispositivo capace di identificare possibili episodi di SB con una sensibilità ed una specificità rispettivamente del 92.3% e del 91.6% quando comparato ad una indagine polisonnografica di tipo III (PSG III).",
          img: ["bruxoff.png"],
        },
        {
          full: true,
          img: ["contenuto-confezione.png"],
        },
        {
          img: ["sensori-code.png"],
          title: "I sensori code",
          paragraph: `Bruxoff+ usa gli elettrodi CoDe per la registrazione elettromiografica da i muscoli masseteri.
            Questi elettrodi innovativi, oggetto di un brevetto USA, offrono due grandi vantaggi rispetto agli elettrodi tradizionali:\n            
            Evitano problemi dovuti all'errato orientamento lungo la direzione delle fibre muscolari.
            Sono in grado di registrare in maniera selettiva l'attività del muscolo investigato`,
        },
        {
          full: true,
          title:
              "Le basi scientifiche del Bruxoff",
          paragraph:
              "- Detection of Sleep Bruxism: Comparison between an Electromyographic and Electrocardiographic Portable Holter and Polysomnography. \n" +
              "- Reliability of a portable device for the detection of sleep bruxism.\n" +
              "- Use of electromyographic and electrocardiographic signals to detect sleep bruxism episodes in a natural environment.\n" +
              "- Genesis of sleep bruxism: motor and autonomic-cardiac interactions.\n" +
              "- Bruxism is mainly regulated centrally, not peripherally.\n" +
              "- Sleep bruxism: validity of clinical research diagnostic criteria in a controlled polysomnographic study.\n" +
              "- Identification of a sleep bruxism subgroup with a higher risk of pain.\n" +
              "- Validità di dispositivo portatile per la diagnosi di bruxismo nel sonno: confronto con esame polisonnografico. \n" +
              "– Master Thesis\n" +
              "- Agreement between clinical and portable EMG/ECG diagnosis of sleep bruxism.\n" +
              "- Diagnostic accuracy of portable instrumental devices to measure sleep bruxism: a systematic literature review of polysomnographic studies.",
        },
      ],
      faq:{
        title: "FAQ",
        rows: [
          {
            title: "Quando collego il Bruxoff al PC compare il messaggio “Analizza e correggi”. Cosa significa?",
            content: `Significa che c’è un file corrotto nella memoria interna di Bruxoff. Il file corrotto è dovuto alla disconnessione del cavetto di connessione agli elettrodi durante l’acquisizione. Non si può cancellare il file tramite il PC. Comunque, Bruxoff funziona correttamente anche con il file corrotto. Se vuoi risolvere il problema, devi formattare il Bruxoff. Per farlo, seleziona Bruxoff da Il Mio Computer, clicca con il tasto destro del mouse e seleziona “formatta”. L’operazione impiega alcuni minuti; per ulteriori informazioni consulta il manuale dell’utente.`,
          },
          {
            title: "Che cosa succede se il paziente dimentica di effettuare le tre contrazioni massimali?",
            content:
                "Per ottenere un’analisi precisa dei dati, e perciò la creazione di un resoconto preciso, è essenziale che il paziente esegua le tre contrazioni massimali prima di andare a dormire. Il software che analizza i dati usa questi tre valori per determinare le soglie richieste ad identificare le contrazioni muscolari associate agli episodi di bruxismo. Se le contrazioni massimali non sono state effettuate, la registrazione deve essere ripetuta.",
          },
          {
            title: "Come posso sapere se i segnali registrati sono buoni?",
            content: <p>Un semplice controllo può essere effettuato guardando la schermata che appare subito dopo l’elaborazione dei dati. La finestra mostrerà due grafici sulla destra, che indicano l’attività del muscolo massetere. Ogni linea in questo grafico indica una contrazione muscolare: le linee blu e verdi indicano contrazioni di buona qualità, mentre linee rosse e grigie indicano contrazioni di scarsa qualità (per interferenze o saturazione del segnale). Segnali di scarsa qualità sono collegati a specifiche situazioni, come il distacco degli elettrodi, un’insufficiente preparazione della cute, o anche se gli elettrodi sono riutilizzati. Perciò se vedi diffuse aree rosse o grigie nei grafici, la registrazione dovrà essere ripetuta.<br/>
              Nella stessa finestra, il terzo grafico mostra la frequenza cardiaca. In questo caso, un segnale di scarsa qualità è indicato dall’assenza della frequenza cardiaca: il distacco degli elettrodi, l’insufficiente preparazione della cute, o il riutilizzo degli elettrodi sono le possibili cause.<br/>
              Se riscontri i segnali di scarsa qualità, chiedi al paziente d ripetere l’esame, pulendo e strofinando la cute prima di posizionare gli elettrodi.<br/>
              Questo è un esempio di registrazione di scarsa qualità:<br/><br/>
              <img src="/images/FAQ-SegnaleCattivo.png" alt="Bruxoff+ cattivo segnale"/><br/><br/>
              Questo è un esempio di registrazione di buona qualità:<br/><br/>
              <img src="/images/Faq-SegnaleBuono.png" alt="Bruxoff+ buon segnale"/><br/></p>,
          },
          {
            title: 'Come posso accorgermi se il paziente non ha effettuato le tre contrazioni massimali in modo corretto?',
            content: <p>Un semplice controllo può essere effettuato guardando lo schermo del PC subito dopo l’elaborazione dei dati. Il grafico in basso a sinistra mostra un dettaglio delle contrazioni massimali identificate. Una linea orizzontale indica la contrazione minima richiesta per effettuare l’analisi in base agli studi scientifici condotti su Bruxoff. Perciò le contrazioni al di sotto della soglia non sono considerate per l’analisi. <br/>
              Questo è un esempio di buone contrazioni massimali:<br/>
              <img src="/images/FAQ-MVC.png" alt="Bruxoff+ buon segnale"/>
            </p>,
          },
          {
            title: 'Posso esportare i segnali del Bruxoff dopo averli scaricati?',
            content: 'I segnali elaborati dal software Bruxmeter sono conservati in una cartella di sistema e possono essere esportati in qualsiasi momento. Il procedimento è descritto nel manuale dell’utente del software Bruxmeter. ' +
                'Bisogna avviare il software, poi selezionare la cartella del paziente e premere il tasto “esporta” sul pannello in alto. Il software chiederà di selezionare la localizzazione in cui esportare i segnali. Una volta selezionata l’adeguata localizzazione, premere invio e attendere alcuni minuti per completare il procedimento.',
          },
          {
            title: 'Posso usare gli stessi elettrodi per registrazioni multiple?',
            content: 'Tutti gli elettrodi del kit Bruxoff sono usa e getta. Perciò si possono utilizzare solo una volta. Questo è dovuto a motivi di igiene e a motivi metodologici: se si vogliono ottenere risultati di qualità, bisogna usare gli elettrodi solo una volta.\n' +
                'Gli elettrodi devono essere applicati sulla cute solo dopo un’adeguata pulizia e strofinamento con alcol. Si consiglia anche di massaggiare ogni elettrodo dopo averlo posizionato per facilitare l’adesione alla cute.\n',
          },
          {
            title: 'Che succede se il paziente preme il tasto start prima di cominciare la vera sessione di registrazione?',
            content: 'A partire dalla versione 1.0.1.3, il software Bruxmeter è in grado di riconoscere la durata di un file. I file che durano meno di 5 minuti non sono considerati per l’elaborazione dei dati. Perciò se il paziente preme il tasto start e poi il tasto stop prima di cominciare la vera sessione di registrazione, non è un problema.',
          },
          {
            title: 'Posso fornire il dispositivo Bruxoff anche se la batteria non è completamente carica?',
            content: 'Con la batteria completamente carica, il Bruxoff può registrare dati per più di 20 ore. Perciò probabilmente si può registrare una notte intera anche se il dispositivo non è completamente carico. Comunque, si consiglia vivamente di controllare le luci LED sul dispositivo quando è sotto carica. Se la luce LED ROSSA lampeggia, significa che il livello di carica non è sufficiente per la registrazione di una notte.\n' +
                'Ricorda che 3 ore di ricarica assicureranno una registrazione di almeno 9 ore, qualunque sia lo stato iniziale della batteria. Perciò è una buona idea lasciare Bruxoff in ricarica per almeno 3 ore prima di fornirlo al paziente.',
          },
          {
            title: 'La luce led rossa lampeggia. Continua a lampeggiare anche se la batteria è carica. Cosa significa?',
            content: 'Significa che la memoria interna di Bruxoff è stata danneggiata. Contatta il tuo distributore locale o contattaci all’indirizzo helpdesk@orthorizon.eu',
          },
          {
            title: 'Quando collego il Bruxoff al PC, non compare la finestra di Bruxoff. Nel software Bruxmeter l’icona Bruxoff non è attiva. Cosa significa?',
            content: 'Significa che si possono essere verificati due problemi tecnici: 1) il cavetto che connette il Bruxoff al PC è stato danneggiato, o 2) il connettore interno del Bruxoff è stato danneggiato. Contatta il tuo distributore locale o contattaci all’indirizzo helpdesk@orthorizon.eu',
          },
        ],
      }
    },
    tech: {
      blocks: [
        {
          title:
          "La tecnologia dentro Bruxoff.",
          paragraph:
          "Vengono raccolti i segnali EMG di entrambi i muscoli masseteri mediante elettrodi " +
              "concentrici in AgCl e la frequenza cardiaca mediante una fascia toracica di tre " +
              "elettrodi in AgCl. I segnali grezzi vengono archiviati nella memoria interna del " +
              "dispositivo Bruxoff®. Successivamente devono essere analizzati dal software " +
              "Bruxmeter®, che è in grado di fornire un report sul numero e sul tipo di contrazioni " +
              "muscolari: toniche (o sostenute, ovvero contrazioni della durata >= 2 secondi), fasiche " +
              "(o ritmiche, ovvero brevi contrazioni che si ripetono con tre o più accessi con una " +
              "durata complessiva che varia da 0.25 a 2 secondi) e miste (dove possono essere " +
              "riscontrati entrambi i fenomeni). Questi dati vengono ulteriormente implementati " +
              "dal riconoscimento in automatico da parte del sotware del numero di episodi di SB " +
              "per ora di sonno, dando al clinico un quadro completo della situazione dell’attività " +
              "EMG nel sonno del paziente.",
          img: ["Circuito.png"],
        },
        {
          img: ["bio-elettronica.png"],
          title:
          "OT Bioelettronica.\n We make hardware and software",
          paragraph:
          "La ditta OT Bioelettronica, fondata nel febbraio 2007 ha sede a Torino e si occupa di Ricerca e Sviluppo " +
              "nel settore elettronico. La OT Bioelettronica vanta una specializzazione particolare nello sviluppo e " +
              "nella produzione in piccola serie, di apparecchiature elettromedicali. Caratteristica che nasce " +
              "dall'esperienza del proprio personale che è stato formato nel settore della ricerca internazionale. " +
              "Durante questo periodo sono state prodotte pubblicazioni su riviste specializzate di settore.\n\n"+
              "Tra i clienti dell'azienda si annoverano Centri Ricerca ed Università di tutto il mondo (si faccia " +
              "riferimento alla pagina delle collaborazioni). " +
              "La ditta OT Bioelettronica si occupa anche di progettazione elettronica ad ampio spettro toccando " +
              "settori che vanno dall'automotive all'home care, la finalità unica è quella di concretizzare le idee dei " +
              "clienti in prodotti commerciali sviluppati ad hoc (anche in singoli esemplari).\n\n"+
              "La OT Bioelettronica grazie agli stretti contatti con aziende operanti in settori diversi dall'elettronica " +
              "(meccanica ed informatica) può fornire un prodotto completo che evita al cliente di dover gestire " +
              "l'outsourcing e di occuparsi solamente della vendita del prodotto.",
        }
      ],
    },
    howto: {
      blocks: [
        {
          title:
            "Come si usa.",
          paragraph:
            "Il dispositivo Bruxoff® plus è l’holter portatile di riferimento nella pratica clinica per l’indagine del bruxismo del sonno: grazie alla sua semplicità di applicazione permette un facile impiego domiciliare per il paziente ed una pratica e precisa valutazione del follow up terapeutico.\n",
          img: ["come_si_usa.png"],
        },
      ],
      doctor: "Per il medico",
      patient: "Per il paziente",
      slide1: "Connettere il cavetto di connessione (B) alle tre clip elettrocardiografiche (D), nel corretto ordine numerico/codice colore.",
      slide2: "Posizionare l’elettrodo elettromiografico (D) sul lato sinistro del torace, circa 5-10 cm al di sotto dello sterno, subito al di sotto del muscolo pettorale di sinistra.\n" +
               "L’area di applicazione deve essere depilata e pulita con alcol: non applicare  saponi, olii o creme su quest’area prima di posizionare l’elettrodo.\n",
      slide3: "Una volta completato il passaggio precedente, indossare la fascia toracica (E) del Bruxoff.",
      slide4: "Connettere il Bruxoff (A) al cavetto di connessione agli elettrodi (B)",
      slide5: "Inserire il Bruxoff (A) nella tasca della cintura toracica (E)",
      slide6: "Posizionare gli elettrodi elettromiografici (C) sul muscolo massetere. Posizionarli tra lo zigomo e l’angolo della mandibola, dove il muscolo sporge durante il serramento dei denti. \n" +
              "L’area di applicazione dev’essere depilata e pulita con alcol: non applicare saponi, olii o creme su quest’area prima di posizionare l’elettrodo.",
      slide7: "Collegare il cavetto di connessione (B) agli elettrodi EMG (C), il connettore 4 all’elettrodo che si troverà alla propria destra, il connettore 5 all’elettrodo che si troverà alla 07 propria sinistra",
      slide8: "Collegare il cavetto di connessione (B) agli elettrodi EMG (C), il connettore 4 all’elettrodo che si troverà alla propria destra, il connettore 5 all’elettrodo che si troverà alla 07 propria sinistra",
      slide9: "Avviare la registrazione premendo il tasto start/stop sul Bruxoff (F).",
      slide10: "Stringere i denti 3 volte con la massima forza possibile e andare a dormire (ogni stretta dovrebbe durare circa 2 secondi, con 5 secondi di riposo tra le strette).",
    },
    download: {
      blocks: [
        {
          title: "Bruxoff certificazioni",
          content:
            "The Bruxmeter software performs an automatic scoring of the sleep bruxism events recorded during one or more nights of sleep.",
          files: [
            {
              name: "Certificato Bruxoff",
              url: "/download/Dich_Conf_Bruxoff+.pdf",
              label: "Certificato Bruxoff",
            },
            {
              name: "Certificato Code",
              url: "/download/DIC_CE_Elettrodi_momouso_di_superficie_per_EEG_e_EMG  2020.pdf",
              label: "Certificato Code",
            },
            {
              name: "Dichiarazione di conformità",
              url: "/download/Conformity_Declaration_Bruxoff+.pdf",
              label: "Dichiarazione di conformità",
            }
          ],
        },
        {
          title: "Bruxoff manuale",
          content:
            "The Bruxmeter software performs an automatic scoring of the sleep bruxism events recorded during one or more nights of sleep.",
          files: [
            {
              name: "Manuale d'uso BruxMeter+ it",
              url: "/download/BruxMeter+_manuale_utente_v1.1.pdf",
              label: "Manuale BruxMeter+ it",
            },
            {
              name: "Manuale d'uso BruxMeter+ en",
              url: "/download/BruxMeter+_use_manual v1.1.pdf",
              label: "Manuale BruxMeter en",
            },
            {
              name: "Manuale d'uso Bruxoff+ it",
              url: "/download/Bruxoff+_manuale_utente_v1.2.pdf",
              label: "Manuale Bruxoff+ it",
            },
            {
              name: "Manuale d'uso Bruxoff+ en",
              url: "/download/Bruxoff+_user_manual v1.1.pdf",
              label: "Manuale Bruxoff+ en",
            },
            {
              name: "Manuale d'uso Bruxoff+ fr",
              url: "/download/Bruxoff+_manuel_d'utilisation.pdf",
              label: "Manuale Bruxoff+ fr",
            },
          ],
        },
        {
          title: "Bruxoff software",
          content: `
            The Bruxmeter software performs an automatic scoring of the sleep bruxism events recorded during one or more nights of sleep.
            \nIt is an user friendly software: once the patient gives you back the Bruxoff, connect it to your laptop with the provided cable and follows the Bruxmeter instructions.`,
          files: [
            {
              name: "Bruxmeter",
              url: "/download/BruxmeterSetup.2.0.2.4.zip",
              label: "SW bruxmeter release 2.0.2.4",
            },
            {
              name: "Bruxmeter+",
              url: "/download/BruxmeterSetup.2.0.2.7.zip",
              label:
                "SW Bruxmeter+ release 2.0.2.7",
            },
          ],
        },
      ],
    },
    contacts: {
      title: "Contatti",
      form: {
        name: "Il tuo nome",
        mail: "La tua mail",
        message: "Il tuo messaggio",
        submit: "Invia",
        send: "Messaggio inviato correttamente",
        error: "Errore nell'invio della mail, si prega di riprovare."
      },
      paragraph: `
      Contattaci utilizzando il form o inviando una mail a dpiccenna@gmail.com per ricevere informazioni.
      Bruxoff è distribuito da Allder`,
    },
  },
  en: {
    flag: ["/images/en.png"],
    label: "English",
    nav: {
      home: "Home",
      about: "Bruxism",
      bruxoff: "Bruxoff",
      tech: "Technology",
      download: "Download",
      howto: "How to",
      shop: "Shop",
      contacts: "Contacts",
    },
    footer: {
      distributedBy: "Distribuited by",
      producedBy: "Produced by",
    },
    home: {
      blocks: [
        {
          img: ["home-01.png"],
          title:
            "Bruxism is a major cause of muscle and joint pain, waking headache, tooth wear, and dental implant compromise.",
          paragraph: `Bruxism is a parafunction characterized by clenching and grinding of teeth during sleep, associated with micro-wakings. 
          Recent studies have shown how bruxism is associated with an activation of the autonomic nervous system causing a cascade of physiological events that ends with an increase in heart rate preceding a few seconds 
          to the contraction of the masseter muscles.`,
        },
        {
          title: `Bruxoff.
            Diagnosis of Bruxism`,
          paragraph: `Extremely simple, small and portable, Bruxoff is the new portable holter that can simultaneously record masseter muscle and heart activity.`,
          button: "Discover more",
          link: "/bruxoff",
          img: ["home-02.gif"],
        },
        {
          img: ["home-03.png"],
          title: `Bruxoff.
            Diagnosis of Bruxism`,
          paragraph: `Extremely simple, small and portable, Bruxoff is the new portable holter that can simultaneously record masseter muscle and heart activity.`,
          link: "/about",
          button: "Discover more",
        },
      ],
    },
    about: {
      blocks: [
        {
          title:
            "Bruxism is derived from the Greek word \"brychein,\" meaning \"grinding and rubbing the two opposite dental arches”",
          subtitle:
            "Bruxism is a major cause of muscle and joint pain, waking headache, tooth wear, and dental implant compromise.",
          img: ["about-01.png"],
        },
        {
          img: ["about-02.png"],
          title: "Diagnosis of bruxism",
          paragraph: `Bruxism is a parafunction characterized by clenching and grinding of teeth during sleep, associated with so-called micro-wakings.
          Recent studies have shown how bruxism is associated with an activation of the autonomic nervous system causing a cascade of physiological events that ends with an increase in heart rate preceding a few seconds of masseter muscle contraction.`,
        },
        {
          full: true,
          items: [
            {
              img: "about-03.gif",
            },
            {
              img: "about-04.gif",
            },
          ],
          title: "Damage caused by bruxism",
          paragraph: `Bruxism is the main cause of implant failure due to occlusal overload. It is also responsible for fractures of prosthetic and conservative restorations. 
                      Its presence can worsen existing clinical conditions of temporomandibular disorders both muscular and articular`,
          subParagraph: `Bruxism can be called waking or sleeping bruxism depending on its circadian manifestations. The waking form (AB 'awake bruxism') has strong associations with high levels of stress, smoking and nicotine. 
                  In contrast, sleep bruxism (SB 'sleep bruxism') is mainly regulated at the level of the central nervous system and is not influenced by occlusal interference. 
                  Some associations have been found with alcohol intake and smoking, but it remains predominantly a sleep disorder characterised by clenching or grinding, even mandibular protrusion associated with episodes of micro-arousals at the central level.`,

        },
      ],
    },
    bruxoff: {
      blocks: [
        {
          title:
              "Bruxoff®",
          paragraph:
              "Bruxoff® a portable holter to monitor electromyographic (EMG) signals of the masseter muscles and heart rate in patients in whom SB or excess EMG activity of the masticatory muscles is suspected, which may be the cause of detrimental effects on the stomatogastic system such as: prosthetic failures, tooth wear, orofacial pain.\n" +
              "The collection of both of these signals make the Bruxoff® device a validated tool for the instrumental investigation of SB, as the correlation between increased EMG activity of masseter muscles and heart rate in sleep to events of micro-awakenings (arousals) of the central nervous system in which episodes of SB can also be varified has been shown in the literature.\n" +
              "The collection of both of these signals makes the device capable of identifying possible SB episodes with a sensitivity and specificity of 92.3 percent and 91.6 percent, respectively, when compared to a type III polysomnographic survey (PSG III).",
          img: ["bruxoff.png"],
        },
        {
          full: true,
          img: ["contenuto-confezione.png"],
        },
        {
          img: ["sensori-code.png"],
          title: "The code's sensor",
          paragraph: `Bruxoff+ uses CoDe electrodes for electromyographic recording from the masseter muscles.
            These innovative electrodes, the subject of a U.S. patent, offer two major advantages over traditional electrodes:            
            They avoid problems due to misorientation along the direction of muscle fibers.
            They are able to selectively record the activity of the investigated muscle`,
        },
        {
          full: true,
          title:
              "The scientific basis of the Bruxoff",
          paragraph:
              "- Detection of Sleep Bruxism: Comparison between an Electromyographic and Electrocardiographic Portable Holter and Polysomnography. \n" +
              "- Reliability of a portable device for the detection of sleep bruxism.\n" +
              "- Use of electromyographic and electrocardiographic signals to detect sleep bruxism episodes in a natural environment.\n" +
              "- Genesis of sleep bruxism: motor and autonomic-cardiac interactions.\n" +
              "- Bruxism is mainly regulated centrally, not peripherally.\n" +
              "- Sleep bruxism: validity of clinical research diagnostic criteria in a controlled polysomnographic study.\n" +
              "- Identification of a sleep bruxism subgroup with a higher risk of pain.\n" +
              "- Validity of portable device for the diagnosis of bruxism in sleep: comparison with polysomnographic examination. \n" +
              "– Master Thesis\n" +
              "- Agreement between clinical and portable EMG/ECG diagnosis of sleep bruxism.\n" +
              "- Diagnostic accuracy of portable instrumental devices to measure sleep bruxism: a systematic literature review of polysomnographic studies.",
        },
      ],
      faq:{
        title: "FAQ",
        rows: [
          {
            title: "When I connect the Bruxoff to the PC, the message 'Analyse and Correct' appears. What does this mean?",
            content: `This means that there is a corrupt file in the internal Bruxoff memory. The corrupt file is due to the electrode connection cable being disconnected during acquisition. The file cannot be deleted via the PC. However, Bruxoff works correctly even with the corrupted file. If you want to solve the problem, you have to format Bruxoff. To do this, select Bruxoff from My Computer, right-click and select 'format'. The operation takes a few minutes; see the user manual for more information.`,
          },
          {
            title: "What happens if the patient forgets to perform the three maximal contractions?",
            content:"In order to obtain an accurate analysis of the data, and therefore the creation of an accurate report, it is essential that the patient performs the three maximal contractions before going to sleep. The software that analyses the data uses these three values to determine the thresholds required to identify the muscle contractions associated with bruxism episodes. If the maximal contractions are not performed, the recording must be repeated.",
          },
          {
            title: "How do I know if the recorded signals are good?",
            content: <p>A simple check can be made by looking at the screen that appears immediately after processing the data. The window will show two graphs on the right, indicating the activity of the masseter muscle. Each line in this graph indicates a muscle contraction: blue and green lines indicate good quality contractions, while red and grey lines indicate poor quality contractions (due to interference or signal saturation). Poor quality signals are related to specific situations, such as electrode detachment, insufficient skin preparation, or even if the electrodes are reused. Therefore, if you see widespread red or grey areas in the graphs, the recording will have to be repeated.<br/>
              In the same window, the third graph shows the heart rate. In this case, a poor quality signal is indicated by the absence of heart rate: electrode detachment, insufficient skin preparation, or re-use of electrodes are possible causes.<br/>
              If you find signs of poor quality, ask the patient to repeat the examination, cleaning and rubbing the skin before placing the electrodes.<br/>
              This is an example of a poor quality recording:<br/><br/>
              <img src="/images/FAQ-SegnaleCattivo.png" alt="Bruxoff+ cattivo segnale"/><br/><br/>
              This is an example of a good quality recording:<br/><br/>
              <img src="/images/Faq-SegnaleBuono.png" alt="Bruxoff+ buon segnale"/><br/></p>,
          },
          {
            title: 'How can I tell if the patient has not performed the three maximal contractions correctly?',
            content: <p>A simple check can be made by looking at the PC screen immediately after data processing. The bottom left graph shows a detail of the maximum contractions identified. A horizontal line indicates the minimum contraction required to perform the analysis according to the scientific studies conducted on Bruxoff. Therefore, contractions below the threshold are not considered for analysis. <br/>
              This is an example of good maximal contractions:<br/>
              <img src="/images/FAQ-MVC.png" alt="Bruxoff+ buon segnale"/>
            </p>,
          },
          {
            title: 'Can I export the Bruxoff signals after downloading them?',
            content: 'The signals processed by the Bruxmeter software are stored in a system folder and can be exported at any time. The procedure is described in the Bruxmeter software user manual. ' +
                'You have to start the software, then select the patient\'s folder and press the "export" button on the top panel. The software will prompt you to select the location to which you want to export the signals. Once the appropriate location has been selected, press enter and wait a few minutes to complete the process.',
          },
          {
            title: 'Can I use the same electrodes for multiple recordings?',
            content: 'All electrodes in the Bruxoff kit are disposable. Therefore, they can only be used once. This is due to hygienic and methodological reasons: if you want quality results, you only have to use the electrodes once.\n' +
                'Electrodes should only be applied to the skin after adequate cleaning and rubbing with alcohol. It is also recommended to massage each electrode after placement to facilitate adhesion to the skin.\n',
          },
          {
            title: 'What happens if the patient presses the start button before starting the actual recording session?',
            content: 'As of version 1.0.1.3, the Bruxmeter software is able to recognise the duration of a file. Files lasting less than 5 minutes are not considered for data processing. Therefore if the patient presses the start button and then the stop button before starting the actual recording session, this is not a problem.',
          },
          {
            title: 'Can I supply the Bruxoff device even if the battery is not fully charged?',
            content: 'With a fully charged battery, the Bruxoff can record data for more than 20 hours. Therefore, you can probably record an entire night even if the device is not fully charged. However, it is highly recommended to check the LED lights on the device when it is charging. If the RED LED light flashes, it means that the charge level is not sufficient for one night\'s recording.\n' +
                'Remember that 3 hours of charging will ensure a recording of at least 9 hours, whatever the initial state of the battery. Therefore it is a good idea to leave Bruxoff charging for at least 3 hours before giving it to the patient.',
          },
          {
            title: 'The red LED light flashes. It continues to flash even if the battery is charged. What does this mean?',
            content: 'This means that the internal Bruxoff memory has been damaged. Contact your local distributor or contact us at helpdesk@orthorizon.eu',
          },
          {
            title: 'When I connect the Bruxoff to the PC, the Bruxoff window does not appear. In the Bruxmeter software, the Bruxoff icon is not active. What does this mean?',
            content: 'This means that two technical problems may have occurred: 1) the cable connecting the Bruxoff to the PC has been damaged, or 2) the internal connector of the Bruxoff has been damaged. Contact your local distributor or contact us at helpdesk@orthorizon.eu.',
          },
        ],
      }
    },
    tech: {
      blocks: [
        {
          title:
              "Technology inside Bruxoff.",
          paragraph:
              "EMG signals of both masseter muscles are collected by means of electrodes " +
              "concentric AgCl and heart rate by means of a chest strap of three " +
              "AgCl electrodes. The raw signals are stored in the internal memory of the " +
              "Bruxo® device. They must then be analysed by the software " +
              "Bruxmeter®, which is able to provide a report on the number and type of contractions " +
              "muscles: tonic (or sustained, i.e. contractions lasting >= 2 seconds), phasic " +
              "(or rhythmic, i.e. short contractions that are repeated with three or more on one " +
              "total duration ranging from 0.25 to 2 seconds) and mixed (where both phenomena may be " +
              "both phenomena can be observed). These data are further implemented " +
              "by the automatic recognition by the sotware of the number of SB episodes " +
              "per hour of sleep, giving the clinician a complete picture of the activity situation " +
              "EMG in the patient's sleep.",
          img: ["Circuito.png"],
        },
        {
          img: ["bio-elettronica.png"],
          title:
              "OT Bioelettronica.\n We make hardware and software",
          paragraph:
              "The company OT Bioelettronica, founded in February 2007, is based in Turin and deals with research and development " +
              "in the electronics sector. OT Bioelettronica has a particular specialisation in the development and " +
              "in the small series production of electromedical equipment. A feature that stems " +
          "from the experience of its staff, who have been trained in international research. " +
                  "During this period, publications were produced in specialised trade journals. \n\n"+
              "The company's customers include research centres and universities from all over the world (please refer " +
              "refer to the Collaborations page). " +
              "The company OT Bioelettronica is also involved in wide-ranging electronic design, touching on " +
              "sectors ranging from automotive to home care, the sole purpose being to realise the ideas of " +
              "customers' ideas into ad-hoc developed commercial products (also in single units).\n\n "+
              "Thanks to its close contacts with companies operating in sectors other than electronics, OT Bioelettronica " +
              "(mechanics and IT) can provide a complete product that avoids the customer having to manage " +
              "outsourcing and dealing only with the sale of the product. ",
        }
      ]
    },
    howto: {
      blocks: [
        {
          title:
              "How to use.",
          paragraph:
              "The Bruxoff® plus device is the reference hand-held holter in clinical practice for the investigation of sleep bruxism: thanks to its simplicity of application, it allows easy home use for the patient and a practical and precise evaluation of therapeutic follow-up.\n",
          img: ["come_si_usa.png"],
        },
      ],
      doctor: "For the doctor",
      patient: "For the patient",
      slide1: "Connect the connection cable (B) to the three electrocardiographic clips (D), in the correct number/colour code order.",
      slide2: "Place the electromyography electrode (D) on the left side of the chest, about 5-10 cm below the sternum, just below the left pectoral muscle.\n" +
          "The application area must be shaved and cleaned with alcohol: do not apply soaps, oils or creams to this area before placing the electrode.\n",
      slide3: "Once the previous step has been completed, put on the Bruxoff's chest strap (E).",
      slide4: "Connect the Bruxoff (A) to the electrode connection cable (B).",
      slide5: "Insert the Bruxoff (A) into the chest belt pocket (E).",
      slide6: "Place the electromyography electrodes (C) on the masseter muscle. Position them between the cheekbone and the angle of the jaw, where the muscle protrudes during the clenching of the teeth. \n" +
          "The application area must be shaved and cleaned with alcohol: do not apply soaps, oils or creams to this area before placing the electrode.",
      slide7: "Connect the connection cable (B) to the EMG electrodes (C), connector 4 to the electrode on your right, connector 5 to the electrode on your left.",
      slide8: "Connect the connection cable (B) to the EMG electrodes (C), connector 4 to the electrode on your right, connector 5 to the electrode on your left.",
      slide9: "Start recording by pressing the start/stop button on the Bruxoff (F).",
      slide10: "Squeeze 3 times with as much force as possible and go to sleep (each squeeze should last about 2 seconds, with 5 seconds rest between squeezes).",
    },
    download: {
      blocks: [
        {
          title: "Bruxoff certifications",
          content:
            "The Bruxmeter software performs an automatic scoring of the sleep bruxism events recorded during one or more nights of sleep.",
          files: [
            {
              name: "Bruxoff certificate",
              url: "/download/Dich_Conf_Bruxoff+.pdf",
              label: "Bruxoff certificate",
            },
            {
              name: "Code certificate",
              url: "/download/DIC_CE_Elettrodi_momouso_di_superficie_per_EEG_e_EMG  2020.pdf",
              label: "Code certificate",
            },
            {
              name: "Declaration of Conformity",
              url: "/download/Conformity_Declaration_Bruxoff+.pdf",
              label: "Declaration of Conformity",
            }
          ],
        },
        {
          title: "Bruxoff manual",
          content:
            "The Bruxmeter software performs an automatic scoring of the sleep bruxism events recorded during one or more nights of sleep.",
          files: [
            {
              name: "BruxMeter+ User Manual it",
              url: "/download/BruxMeter+_manuale_utente_v1.1.pdf",
              label: "BruxMeter+ Manual it"
            },
            {
              name: "BruxMeter+ User Manual en",
              url: "/download/BruxMeter+_use_manual v1.1.pdf",
              label: "BruxMeter+ Manual en"
            },
            {
              name: "Bruxoff+ User Manual it",
              url: "/download/Bruxoff+_manuale_utente_v1.2.pdf",
              label: "Bruxoff+ Manual it"
            },
            {
              name: "Bruxoff+ User Manual en",
              url: "/download/Bruxoff+_user_manual v1.1.pdf",
              label: "Bruxoff+ Manual en"
            },
            {
              name: "Bruxoff+ User Manual fr",
              url: "/download/Bruxoff+_manuel_d'utilisation.pdf",
              label: "Bruxoff+ Manual fr"
            },
          ],
        },
        {
          title: "Bruxoff software",
          content: `
            The Bruxmeter software performs an automatic scoring of the sleep bruxism events recorded during one or more nights of sleep. \n
            It is an user friendly software: once the patient gives you back the Bruxoff, connect it to your laptop with the provided cable and follows the Bruxmeter instructions.`,
          files: [
            {
              name: "Bruxmeter",
              url: "/download/BruxmeterSetup.2.0.2.4.zip",
              label: "SW bruxmeter release 2.0.2.4",
            },
            {
              name: "Bruxmeter +",
              url: "/download/BruxmeterSetup.2.0.2.7.zip",
              label:
                  "SW Bruxmeter + release 2.0.2.7",
            },
          ],
        },
      ],
    },
    contacts: {
      title: "Contact us",
      form: {
        name: "Your name",
        mail: "Your email",
        message: "Your message",
        submit: "Submit",
        send: "Message sent correctly",
        error: "Error sending mail, please try again."
      },
      paragraph: `
      Contacts us by using the form or by sending an email at dpiccenna@gmail.com to receive more information.
      Bruxoff is distributed by Allder`,
    },
  },
};

export default translation;

//Dependencies & helpers
import React, { useEffect } from "react";
import { UserContext, translation } from "../../helpers";
//Components
import DownloadBlock from "./DownloadBlock/DownloadBlock";
//Styles
import "./Download.css";

const Download = () => {
  //Use translations
  const [state] = React.useContext(UserContext);
  const t = translation[state.lang].download.blocks;

  //Return all downdloab blocks
  const returnBlocks = () => {
    return Object.keys(t).map((block, index) => {
      return <DownloadBlock key={index} props={t[block]}></DownloadBlock>;
    });
  };

  useEffect(() => {
    document.querySelector(".main").scrollIntoView({
      block: "start",
    });
  }, []);

  return (
    <section id="download" className="download">
      {returnBlocks()}
    </section>
  );
};

export default Download;

//@props
//content String

//Styles
import "./Button.css";

const Button = ({ props }) => {

  if (props.link !== undefined) {
    return <a href={props.link}><button className="btn" >{props.content}</button></a>;
  }else {
    return <button className="btn" >{props.content}</button>;
  }
};

export default Button;

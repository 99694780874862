//Dependencies & helpers
import React, {useRef} from "react";
import {translation, UserContext} from "../../helpers";
//Components
import {Block} from "../../components";
import Faq from 'react-faq-component';
//Styles
import "./Bruxoff.css";

const data = {
  title: "FAQ",
  rows: [
    {
      title: "Quando collego il Bruxoff al PC compare il messaggio “Analizza e correggi”. Cosa significa?",
      content: `Significa che c’è un file corrotto nella memoria interna di Bruxoff. Il file corrotto è dovuto alla disconnessione del cavetto di connessione agli elettrodi durante l’acquisizione. Non si può cancellare il file tramite il PC. Comunque, Bruxoff funziona correttamente anche con il file corrotto. Se vuoi risolvere il problema, devi formattare il Bruxoff. Per farlo, seleziona Bruxoff da Il Mio Computer, clicca con il tasto destro del mouse e seleziona “formatta”. L’operazione impiega alcuni minuti; per ulteriori informazioni consulta il manuale dell’utente.`,
    },
    {
      title: "Che cosa succede se il paziente dimentica di effettuare le tre contrazioni massimali?",
      content:
          "Per ottenere un’analisi precisa dei dati, e perciò la creazione di un resoconto preciso, è essenziale che il paziente esegua le tre contrazioni massimali prima di andare a dormire. Il software che analizza i dati usa questi tre valori per determinare le soglie richieste ad identificare le contrazioni muscolari associate agli episodi di bruxismo. Se le contrazioni massimali non sono state effettuate, la registrazione deve essere ripetuta.",
    },
    {
      title: "Come posso sapere se i segnali registrati sono buoni?",
      content: <p>Un semplice controllo può essere effettuato guardando la schermata che appare subito dopo l’elaborazione dei dati. La finestra mostrerà due grafici sulla destra, che indicano l’attività del muscolo massetere. Ogni linea in questo grafico indica una contrazione muscolare: le linee blu e verdi indicano contrazioni di buona qualità, mentre linee rosse e grigie indicano contrazioni di scarsa qualità (per interferenze o saturazione del segnale). Segnali di scarsa qualità sono collegati a specifiche situazioni, come il distacco degli elettrodi, un’insufficiente preparazione della cute, o anche se gli elettrodi sono riutilizzati. Perciò se vedi diffuse aree rosse o grigie nei grafici, la registrazione dovrà essere ripetuta.<br/>
      Nella stessa finestra, il terzo grafico mostra la frequenza cardiaca. In questo caso, un segnale di scarsa qualità è indicato dall’assenza della frequenza cardiaca: il distacco degli elettrodi, l’insufficiente preparazione della cute, o il riutilizzo degli elettrodi sono le possibili cause.<br/>
      Se riscontri i segnali di scarsa qualità, chiedi al paziente d ripetere l’esame, pulendo e strofinando la cute prima di posizionare gli elettrodi.<br/>
      Questo è un esempio di registrazione di scarsa qualità:<br/>
      <img src="/images/FAQ-SegnaleCattivo.png" alt="Bruxoff+ cattivo segnale"/><br/>
      Questo è un esempio di registrazione di buona qualità:<br/>
      <img src="/images/Faq-SegnaleBuono.png" alt="Bruxoff+ buon segnale"/><br/></p>,
    },
    {
      title: 'Come posso accorgermi se il paziente non ha effettuato le tre contrazioni massimali in modo corretto?',
      content: <p>Un semplice controllo può essere effettuato guardando lo schermo del PC subito dopo l’elaborazione dei dati. Il grafico in basso a sinistra mostra un dettaglio delle contrazioni massimali identificate. Una linea orizzontale indica la contrazione minima richiesta per effettuare l’analisi in base agli studi scientifici condotti su Bruxoff. Perciò le contrazioni al di sotto della soglia non sono considerate per l’analisi. <br/>
          Questo è un esempio di buone contrazioni massimali:<br/>
        <img src="/images/FAQ-MVC.png" alt="Bruxoff+ buon segnale"/>
      </p>,
    },
    {
      title: 'Posso esportare i segnali del Bruxoff dopo averli scaricati?',
      content: 'I segnali elaborati dal software Bruxmeter sono conservati in una cartella di sistema e possono essere esportati in qualsiasi momento. Il procedimento è descritto nel manuale dell’utente del software Bruxmeter. ' +
          'Bisogna avviare il software, poi selezionare la cartella del paziente e premere il tasto “esporta” sul pannello in alto. Il software chiederà di selezionare la localizzazione in cui esportare i segnali. Una volta selezionata l’adeguata localizzazione, premere invio e attendere alcuni minuti per completare il procedimento.',
    },
    {
      title: 'Posso usare gli stessi elettrodi per registrazioni multiple?',
      content: 'Tutti gli elettrodi del kit Bruxoff sono usa e getta. Perciò si possono utilizzare solo una volta. Questo è dovuto a motivi di igiene e a motivi metodologici: se si vogliono ottenere risultati di qualità, bisogna usare gli elettrodi solo una volta.\n' +
          'Gli elettrodi devono essere applicati sulla cute solo dopo un’adeguata pulizia e strofinamento con alcol. Si consiglia anche di massaggiare ogni elettrodo dopo averlo posizionato per facilitare l’adesione alla cute.\n',
    },
    {
      title: 'Che succede se il paziente preme il tasto start prima di cominciare la vera sessione di registrazione?',
      content: 'A partire dalla versione 1.0.1.3, il software Bruxmeter è in grado di riconoscere la durata di un file. I file che durano meno di 5 minuti non sono considerati per l’elaborazione dei dati. Perciò se il paziente preme il tasto start e poi il tasto stop prima di cominciare la vera sessione di registrazione, non è un problema.',
    },
    {
      title: 'Posso fornire il dispositivo Bruxoff anche se la batteria non è completamente carica?',
      content: 'Con la batteria completamente carica, il Bruxoff può registrare dati per più di 20 ore. Perciò probabilmente si può registrare una notte intera anche se il dispositivo non è completamente carico. Comunque, si consiglia vivamente di controllare le luci LED sul dispositivo quando è sotto carica. Se la luce LED ROSSA lampeggia, significa che il livello di carica non è sufficiente per la registrazione di una notte.\n' +
          'Ricorda che 3 ore di ricarica assicureranno una registrazione di almeno 9 ore, qualunque sia lo stato iniziale della batteria. Perciò è una buona idea lasciare Bruxoff in ricarica per almeno 3 ore prima di fornirlo al paziente.',
    },
    {
      title: 'La luce led rossa lampeggia. Continua a lampeggiare anche se la batteria è carica. Cosa significa?',
      content: 'Significa che la memoria interna di Bruxoff è stata danneggiata. Contatta il tuo distributore locale o contattaci all’indirizzo helpdesk@orthorizon.eu',
    },
    {
      title: 'Quando collego il Bruxoff al PC, non compare la finestra di Bruxoff. Nel software Bruxmeter l’icona Bruxoff non è attiva. Cosa significa?',
      content: 'Significa che si possono essere verificati due problemi tecnici: 1) il cavetto che connette il Bruxoff al PC è stato danneggiato, o 2) il connettore interno del Bruxoff è stato danneggiato. Contatta il tuo distributore locale o contattaci all’indirizzo helpdesk@orthorizon.eu',
    },
  ],
};
const styles = {
  bgColor: '#4A2BA3',
  titleTextColor: "white",
  rowTitleColor: "white",
  rowContentColor: 'white',
  arrowColor: "white",
};
const config = {
  expandIcon: "+",
  collapseIcon: "-",
};

const Bruxoff = () => {
  //Use translations
  const [state] = React.useContext(UserContext);
  const t = translation[state.lang].bruxoff;
  //const faq = translation[state.lang].faq;

  const compRef = useRef(null);

  //Render blocks
  const returnBlocks = () => {
    return t.blocks.map((block, index) => {
      return <Block key={index} props={block} />;
    });
  };

  return (
    <section id="bruxoff" ref={compRef}>
      {returnBlocks()}
      <Faq data={t.faq}
           styles={styles}
           config={config}
      />
    </section>
  );
};

export default Bruxoff;

//Dependencies
import React, {useRef} from "react";
import {translation, UserContext} from "../../helpers";
//Components
import {Button} from "../../components";
import emailjs from '@emailjs/browser';
//Styles
import "./Contacts.css";

const Contacts = () => {
  //Use translations
  const [state] = React.useContext(UserContext);
  const t = translation[state.lang].contacts;

  //Send email to managemen
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_rg9oj0p', 'template_gc4cx2y', form.current, 'krXUbNbdQpM8prfAr')
        .then((result) => {
          //console.log(result.text);
          alert(t.form.send);
        }, (error) => {
          alert(t.form.error + " - Error: " +error.text);
        });
  };

  return (
    <section id="contacts">
      <div>
        <h2>{t.title}</h2>
        <form ref={form} onSubmit={sendEmail} >
          <fieldset>
            <input
              type="text"
              name="user_name"
              id="name"
              autoComplete="off"
              placeholder={t.form.name}
            />
          </fieldset>
          <fieldset>
            <input
              type="email"
              name="user_email"
              id="email"
              autoComplete="off"
              placeholder={t.form.mail}
            />
          </fieldset>
          <fieldset>
            <textarea
              name="message"
              id="message"
              autoComplete="off"
              placeholder={t.form.message}
            />
          </fieldset>
          <Button props={{ content: t.form.submit }} type="submit" value="Send"/>
        </form>
      </div>
      <div id="address">
        <p>{t.paragraph}</p>
        <div>
          <address>
            {`AllDer srl Società Unipersonale
            Via Nilde Iotti, 10
            10024 Moncalieri (TO)
            info@allder.it
            dpiccenna@gmail.com
            C.F./P.Iva: 12689450018
            Codice destinatario: WY7PJ6K`}
          </address>
          <img src="images/logo-allder.svg" alt="Allder" />
        </div>
      </div>
    </section>
  );
};

export default Contacts;

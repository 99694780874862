//Dependencies & helpers
import React, { useRef } from "react";
import { UserContext, translation } from "../../helpers";
//Components
import { Block } from "../../components";
//Styles
import "./Tech.css";

const Tech = () => {
  //Use translations
  const [state] = React.useContext(UserContext);
  const t = translation[state.lang].tech;

  const compRef = useRef(null);

  //Render blocks
  const returnBlocks = () => {
    return t.blocks.map((block, index) => {
      return <Block key={index} props={block} />;
    });
  };

  return (
    <section id="tech" ref={compRef}>
      {returnBlocks()}
    </section>
  );
};

export default Tech;

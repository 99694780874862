import "./DownloadBlock.css";

const DownloadBlock = ({ props }) => {
  const renderLinks = () => {
    return props.files.map((el, index) => {
      if (el.name) {
        if (el.url) {
          return (
            <div key={index} className="downloadBlock-item">
              <h5>{el.name}</h5>
              <a href={el.url} target="_blank">{el.label || el.url}</a>
            </div>
          );
        }
        return (
          <div key={index} className="downloadBlock-item">
            <h5>{el.name}</h5>
          </div>
        );
      }
      return (
        <div key={index} className="downloadBlock-item">
          <a href={el.url}>{el.label || el.url}</a>
        </div>
      );
    });
  };
  return (
    <div className="downloadBlock">
      <h3>{props.title}</h3>
      <p>{props.content}</p>
      {renderLinks()}
    </div>
  );
};

export default DownloadBlock;

//Dependencies & helpers
import React from "react";
import { UserContext, translation } from "../../helpers";
//Styles
import "./Shop.css";

const Shop = () => {
  //Use translations
  //const [state] = React.useContext(UserContext);
  //const t = translation[state.lang].shop;

  return (
    <section id="shop">
      <h1>Shop</h1>
    </section>
  );
};
export default Shop;

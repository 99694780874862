//Dependencies & helpers
import React from "react";
import { UserContext, translation } from "../../helpers";
//Styles
import "./Translator.css";

const Translator = () => {
  //Use translations
  const [state, dispatch] = React.useContext(UserContext);
  const t = translation[state.lang];

  //Render all the langauge options
  const renderOptions = () => {
    return Object.keys(translation).map((el) => {
      return (
        <option key={el} value={el}>
          {translation[el].label}
        </option>
      );
    });
  };

  //Change selected language
  const handleChange = (e) => {
    dispatch({ type: "SWITCH_LANGUAGE", payload: e.target.value });
  };

  return (
    <div className="translator" onChange={handleChange}>
      <img src={t.flag} alt={state.lang} />
      <select>{renderOptions()}</select>
    </div>
  );
};

export default Translator;
